<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Team
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-account-group" prominent type="info">
          <div>
            <h3>Managing Teams in unstruct.ai: </h3>
            <ul>
              <li>UnStruct provides centralized management of groups of individuals, typically represented as teams or distribution lists.</li>
              <li>These teams are essential for ensuring effective communication and coordination during incident response.</li>
              <li>The platform allows for easy engagement and notification of these groups, streamlining the incident management process.</li>
              <li>With UnStruct, keeping contact data up to date and accessible becomes more manageable, enhancing overall incident response efficiency.</li>
            </ul>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
        <v-row class="ma-2">
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-2" outlined>
              <v-card-title class="mb-4">
                <div>{{ item.name }}</div>
                <v-spacer></v-spacer>
                <v-chip class="ma-1" color="blue" dark>
                  <v-icon left>mdi-email</v-icon>
                  {{ item.email }}
                </v-chip>
              </v-card-title>
              <v-card-subtitle>
                <v-chip class="ma-1">
                  <v-icon left color="secondary">mdi-account-group</v-icon>
                  {{ item.company }}
                </v-chip>
                <v-chip class="ma-1">
                  <v-icon left color="purple">mdi-account-hard-hat</v-icon>
                  {{ item.evergreen_owner }}
                </v-chip>
                <v-chip class="ma-1">
                  <v-icon v-if="item.is_active" left color="success">mdi-check</v-icon>
                  <v-icon v-else left :color="item.is_active ? 'success' : 'grey'">mdi-close</v-icon>
                  {{ item.is_active ? "Active" : "Inactive"}}
                </v-chip>
              </v-card-subtitle>
              <div class="mt-2">
                <v-chip class="ma-1" v-if="item.filters.length > 0" color="success" dark>
                  <v-icon left>mdi-filter</v-icon>
                  Filters: {{ item.filters.length }}
                </v-chip>
                </div>
              <v-card-text>
                <div v-for="filter in item.filters" :key="filter.id" class="ma-1">
                  <search-filter :filter="filter" />
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="createEditShow(item)">View / Edit</v-btn>
                <v-btn color="error" text @click="removeShow(item)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/team/DeleteDialog.vue"
import NewEditSheet from "@/team/NewEditSheet.vue"
import SearchFilter from "@/search/SearchFilter.vue"

export default {
  name: "TeamTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    SearchFilter,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Company", value: "company", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("team", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]
    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("team", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
